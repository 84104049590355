<app-navbar></app-navbar>
<div [ngClass]="{'h-screen':!isSafari(),'h-screen-safari':isSafari() }" class="flex flex-col md:flex-row"
     style="margin-top: 55px; padding-bottom: 55px;">
  <aside class="bg-white md:border-r md:border-grayLight hidden md:block w-sidebar shrink-0 px-3 relative">
    <app-sidebar></app-sidebar>
  </aside>
  <div class="flex-1 overflow-auto" style="background: #F5F5F5" #pageWindow>
    <router-outlet></router-outlet>
  </div>

  <app-chat-bot></app-chat-bot>
  <app-notification-popup
          class="app-notification-popup-wrapper"
          [notifications$]="loudNotifications$"></app-notification-popup>
</div>
