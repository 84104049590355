import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormLayoutComponent} from './form-layout/form-layout.component';
import {FormInputComponent} from './form-input/form-input.component';
import {FormTableOfContentsComponent} from './form-table-of-contents/form-table-of-contents.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormInputTextComponent} from './field-types/form-input-text/form-input-text.component';
import {FormInputTextblockComponent} from './field-types/form-input-textblock/form-input-textblock.component';
import {PartialsModule} from '../partials/partials.module';
import {FormInputBooleanComponent} from './field-types/form-input-boolean/form-input-boolean.component';
import {FormInputNumberComponent} from './field-types/form-input-number/form-input-number.component';
import {FormInputCurrencyComponent} from './field-types/form-input-currency/form-input-currency.component';
import {FormInputMileageComponent} from './field-types/form-input-mileage/form-input-mileage.component';
import {FormInputDropdownComponent} from './field-types/form-input-dropdown/form-input-dropdown.component';
import {FormInputMultiCheckboxComponent} from './field-types/form-input-multi-checkbox/form-input-multi-checkbox.component';
import {FormInputPhotosComponent} from './field-types/form-input-photos/form-input-photos.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormInputConnectedDropdownComponent} from './field-types/form-input-connected-dropdown/form-input-connected-dropdown.component';
import {FormInputFilteredDropdownComponent} from './field-types/form-input-filtered-dropdown/form-input-filtered-dropdown.component';
import {FormInputDateComponent} from './field-types/form-input-date/form-input-date.component';
import {FormInputColorComponent} from './field-types/form-input-color/form-input-color.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormInputRepeaterComponent} from './field-types/form-input-repeater/form-input-repeater.component';
import {FormInputVehicleModelComponent} from './field-types/form-input-vehicle-model/form-input-vehicle-model.component';
import {FormInputFilesComponent} from './field-types/form-input-files/form-input-files.component';
import {FormInputTimeComponent} from './field-types/form-input-time/form-input-time.component';
import {FieldInputComponent} from './field-input/field-input.component';
import {InlineFieldEditComponent} from './inline-field-edit/inline-field-edit.component';
import {FieldTableComponent} from './field-table/field-table.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {FormInputTextDescriptionComponent} from './field-types/form-input-text-description/form-input-text-description.component';
import {FormInputFilesNlbComponent} from './field-types/form-input-files-nlb/form-input-files-nlb.component';
import {FormInputSignatureComponent} from './field-types/form-input-signature/form-input-signature.component';

// @ts-ignore
import {SignaturePadModule} from '@vijhhh2/ngx-signaturepad';
import {FormInputFilesNlbCustomComponent} from './field-types/form-input-files-nlb-custom/form-input-files-nlb-custom.component';
import {FormInputEnginePowerComponent} from './field-types/form-input-engine-power/form-input-engine-power.component';
import {FormInputPercentComponent} from './field-types/form-input-percent/form-input-percent.component';
import {FormInputClientComponent} from './field-types/form-input-client/form-input-client.component';
import {FormInputVatSearchComponent} from './field-types/form-input-ddv-search/form-input-vat-search.component';
import {FormInputBusinessUnitComponent} from '@app/shared/briefd-form/field-types/form-input-business-unit/form-input-business-unit.component';
import {PipesModule} from '@app/shared/pipes/pipes.module';
import {FormInputPostComponent} from './field-types/form-input-post/form-input-post.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {FormInputTaxNumberComponent} from '@app/shared/briefd-form/field-types/form-input-tax-number/form-input-tax-number.component';
import {TextMaskModule} from '@myndmanagement/text-mask';
import {FormInputTestPlatesAutocompleteComponent} from '@app/shared/briefd-form/field-types/form-input-test-plates-autocomplete/form-input-test-plates-autocomplete.component';
import {FieldTableRowComponent} from '@app/shared/briefd-form/field-table-row/field-table-row.component';
import {QuillEditorComponent} from 'ngx-quill';
import {FormInputRichTextComponent} from '@app/shared/briefd-form/field-types/form-input-rich-text/form-input-rich-text.component';
import {FormInputMultiDropdownComponent} from "@app/shared/briefd-form/field-types/form-input-multi-dropdown/form-input-multi-dropdown.component";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {FormInputInvoiceFilesComponent} from "@app/shared/briefd-form/field-types/form-input-invoice-files/form-input-invoice-files.component";
import { FormInputEmailComponent } from './field-types/form-input-email/form-input-email.component';
import {FormInputDateTimeComponent} from "./field-types/form-input-date-time/form-input-date-time.component";

@NgModule({
  declarations: [
    FormLayoutComponent,
    FormInputComponent,
    FormTableOfContentsComponent,
    FormInputTextComponent,
    FormInputTextblockComponent,
    FormInputBooleanComponent,
    FormInputNumberComponent,
    FormInputCurrencyComponent,
    FormInputMileageComponent,
    FormInputDropdownComponent,
    FormInputMultiCheckboxComponent,
    FormInputPhotosComponent,
    FormInputConnectedDropdownComponent,
    FormInputFilteredDropdownComponent,
    FormInputDateComponent,
    FormInputColorComponent,
    FormInputRepeaterComponent,
    FormInputVehicleModelComponent,
    FormInputFilesComponent,
    FormInputTimeComponent,
    FieldInputComponent,
    InlineFieldEditComponent,
    FieldTableComponent,
    FieldTableRowComponent,
    FormInputTextDescriptionComponent,
    FormInputFilesNlbComponent,
    FormInputFilesNlbCustomComponent,
    FormInputSignatureComponent,
    FormInputEnginePowerComponent,
    FormInputPercentComponent,
    FormInputVatSearchComponent,
    FormInputClientComponent,
    FormInputBusinessUnitComponent,
    FormInputPostComponent,
    FormInputTaxNumberComponent,
    FormInputTestPlatesAutocompleteComponent,
    FormInputBusinessUnitComponent,
    FormInputRichTextComponent,
    FormInputMultiDropdownComponent,
    FormInputInvoiceFilesComponent,
    FormInputEmailComponent,
    FormInputDateTimeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PartialsModule,
    NgxDropzoneModule,
    DragDropModule,
    MatDatepickerModule,
    MatTooltipModule,
    SignaturePadModule,
    PipesModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    TextMaskModule,
    QuillEditorComponent,
    NgMultiSelectDropDownModule,
  ],
    exports: [
        FormLayoutComponent,
        FormInputComponent,
        FormTableOfContentsComponent,
        FormInputRepeaterComponent,
        FieldInputComponent,
        InlineFieldEditComponent,
        FieldTableComponent,
        FieldTableRowComponent,
        FormInputCurrencyComponent,
        FormInputVatSearchComponent,
        FormInputTextComponent,
        FormInputDropdownComponent,
        FormInputBusinessUnitComponent,
        FormInputRichTextComponent
    ]
})
export class BriefdFormModule {
}
