export const environment = {
  name: 'production-app',
  production: true,
  staging: false,
  apiUrl: 'https://api.briefd.io',
  uploadsApiUrl: 'https://uploads.autobrief.io',
  chromeExtensionUrl: 'https://chromewebstore.google.com/detail/quickentry/nkhajogmaiobipcelekkhoinpddddefn',
  edgeExtensionUrl: 'https://microsoftedge.microsoft.com/addons/detail/quickentry/ihfjejhfcbomefebnjcepognohapjhgi',
  chromeExtensionId: 'nkhajogmaiobipcelekkhoinpddddefn',
  edgeExtensionId: 'ihfjejhfcbomefebnjcepognohapjhgi',
  gtmID: 'GTM-KH5BQNX8',
  facebookTrackingID: '437843593839236',
  hotjarTrackingID: '1754688',
  navbarColor: '#2F6C5B',
  chatBot: {
    organizationId: "8d6c590c-ef2d-47b5-93d7-265e1d623dc8",
    template_id: "58d902af-7aa5-4db3-bdc2-f81c9c1048f8",
    integration_name: "manual_settings",
    product_name: "chatbot",
    enabled: false
  },
  version: '<date-commit>',
  kwToHpRatio: 1.36,
  showDoberAvtoAuthHeaderInSettings: false,
  product_fruits: {
    code: 'kLMSgJNkefsfvedp',
  },
  ssr: {
    port: 4002,
    process: 'briefd-production'
  },
  features: {
    routes: {
      demo: false,
    }
  },
  doberAvtoAdBaseUrl: 'https://doberavto.si/oglas/',
  tradingHubBaseUrl: 'https://autobrief.odoo.com/'
};
