<div [style.display]="isHidden ? 'none' : 'block'">
  <label class="mb-1 block" style="white-space: pre-wrap" [for]="field.fieldUuid"
         *ngIf="!hideLabel && field.type !== 'boolean' && field.type !== 'post' && field.type !== 'currency_conversion_rate'">
    <ng-container *ngIf="field.type !== 'vehicle_model' && field.config?.label">
      <span class="leading-normal">{{ field.config.label }}</span>
    </ng-container>
    <app-tooltip *ngIf="field.config.description"
                 class="ml-1" [content]="field.config.description"></app-tooltip>
  </label>

  <ng-container [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="'text'">
      <app-form-input-text [field]="field" [control]="formGroup.controls[field.fieldUuid]"></app-form-input-text>
    </ng-container>

    <ng-container *ngSwitchCase="'email'">
      <app-form-input-email [field]="field" [control]="formGroup.controls[field.fieldUuid]"></app-form-input-email>
    </ng-container>

    <ng-container *ngSwitchCase="'time'">
      <app-form-input-time [field]="field" [control]="formGroup.controls[field.fieldUuid]"></app-form-input-time>
    </ng-container>

    <ng-container *ngSwitchCase="'date_time'">
      <app-form-input-date-time [field]="field" [control]="formGroup.controls[field.fieldUuid]"></app-form-input-date-time>
    </ng-container>

    <ng-container *ngSwitchCase="'textblock'">
      <app-form-input-textblock [field]="field"
                                [control]="formGroup.controls[field.fieldUuid]"></app-form-input-textblock>
    </ng-container>

    <ng-container *ngSwitchCase="'text_rich'">
      <app-form-input-rich-text [field]="field"
                                [control]="formGroup.controls[field.fieldUuid]"></app-form-input-rich-text>
    </ng-container>

    <ng-container *ngSwitchCase="'boolean'">
      <app-form-input-boolean [field]="field" [control]="formGroup.controls[field.fieldUuid]"
                              [formGroup]="formGroup" [hideLabel]="hideLabel"></app-form-input-boolean>
    </ng-container>

    <ng-container *ngSwitchCase="'number'">
      <app-form-input-number [field]="field"
                             [control]="formGroup.controls[field.fieldUuid]"></app-form-input-number>
    </ng-container>

    <ng-container *ngSwitchCase="'percent'">
      <app-form-input-percent [field]="field" [control]="formGroup.controls[field.fieldUuid]"
                              [controls]="formGroup.controls"></app-form-input-percent>
    </ng-container>

    <ng-container *ngSwitchCase="'currency'">
      <app-form-input-currency [field]="field" [control]="formGroup.controls[field.fieldUuid]"
                               [currencySymbol]="field.currencyConversion.currencySymbol"></app-form-input-currency>
    </ng-container>

    <ng-container *ngSwitchCase="'engine_power'">
      <app-form-input-engine-power [field]="field"
                                   [control]="formGroup.controls[field.fieldUuid]"></app-form-input-engine-power>
    </ng-container>

    <ng-container *ngSwitchCase="'currency_conversion_rate'">

    </ng-container>

    <ng-container *ngSwitchCase="'mileage'">
      <app-form-input-mileage [field]="field"
                              [control]="formGroup.controls[field.fieldUuid]"></app-form-input-mileage>
    </ng-container>

    <ng-container *ngSwitchCase="'dropdown'">
      <app-form-input-dropdown [field]="field" [control]="formGroup.controls[field.fieldUuid]"
                               [formGroup]="formGroup"></app-form-input-dropdown>
    </ng-container>

    <ng-container *ngSwitchCase="'multi_dropdown'">
      <app-form-input-multi-dropdown [field]="field" [control]="formGroup.controls[field.fieldUuid]"
                               [formGroup]="formGroup"></app-form-input-multi-dropdown>
    </ng-container>

    <ng-container *ngSwitchCase="'business_unit'">
      <app-form-input-business-unit [field]="field"
                                    [control]="formGroup.controls[field.fieldUuid]"></app-form-input-business-unit>
    </ng-container>

    <ng-container *ngSwitchCase="'multi_checkbox'">
      <app-form-input-multi-checkbox [field]="field"
                                     [control]="formGroup.controls[field.fieldUuid]"></app-form-input-multi-checkbox>
    </ng-container>

    <ng-container *ngSwitchCase="'photos'">
      <app-form-input-photos [field]="field"
                             [control]="formGroup.controls[field.fieldUuid]"></app-form-input-photos>
    </ng-container>

    <ng-container *ngSwitchCase="'public_photos'">
      <app-form-input-photos [field]="field"
                             [control]="formGroup.controls[field.fieldUuid]"></app-form-input-photos>
    </ng-container>

    <ng-container *ngSwitchCase="'proxy_photos'">
      <app-form-input-photos [field]="field"
                             [control]="formGroup.controls[field.fieldUuid]"></app-form-input-photos>
    </ng-container>

    <ng-container *ngSwitchCase="'files'">
      <app-form-input-files [field]="field"
                            [control]="formGroup.controls[field.fieldUuid]"></app-form-input-files>
    </ng-container>

    <ng-container *ngSwitchCase="'proxy_files'">
      <app-form-input-files [field]="field"
                            [control]="formGroup.controls[field.fieldUuid]"></app-form-input-files>
    </ng-container>

    <ng-container *ngSwitchCase="'invoice_files'">
      <app-form-input-invoice-files [field]="field"
                            [control]="formGroup.controls[field.fieldUuid]"></app-form-input-invoice-files>
    </ng-container>

    <ng-container *ngSwitchCase="'nlb_files'">
      <app-form-input-files-nlb [field]="field"
                                [control]="formGroup.controls[field.fieldUuid]"></app-form-input-files-nlb>
    </ng-container>

    <ng-container *ngSwitchCase="'nlb_files_custom'">
      <app-form-input-files-nlb-custom [field]="field"
                                       [control]="formGroup.controls[field.fieldUuid]"></app-form-input-files-nlb-custom>
    </ng-container>

    <ng-container *ngSwitchCase="'connected_dropdown'">
      <app-form-input-connected-dropdown [field]="field"
                                         [control]="formGroup.controls[field.fieldUuid]"></app-form-input-connected-dropdown>
    </ng-container>

    <ng-container *ngSwitchCase="'filtered_dropdown'">
      <app-form-input-filtered-dropdown [field]="field"
                                        [control]="formGroup.controls[field.fieldUuid]"></app-form-input-filtered-dropdown>
    </ng-container>

    <ng-container *ngSwitchCase="'color'">
      <app-form-input-color [field]="field"
                            [control]="formGroup.controls[field.fieldUuid]"></app-form-input-color>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <app-form-input-date [field]="field" [control]="formGroup.controls[field.fieldUuid]"></app-form-input-date>
    </ng-container>

    <ng-container *ngSwitchCase="'repeater'">
      <app-form-input-repeater [field]="field"
                               [control]="formGroup.controls[field.fieldUuid]"></app-form-input-repeater>
    </ng-container>

    <ng-container *ngSwitchCase="'vehicle_model'">
      <app-form-input-vehicle-model [field]="field"
                                    [control]="formGroup.controls[field.fieldUuid]"></app-form-input-vehicle-model>
    </ng-container>

    <ng-container *ngSwitchCase="'text_description'">
      <app-form-input-text-description [field]="field"
                                       [control]="formGroup.controls[field.fieldUuid]"></app-form-input-text-description>
    </ng-container>

    <ng-container *ngSwitchCase="'signature'">
      <app-form-input-signature [field]="field"
                                [control]="formGroup.controls[field.fieldUuid]"></app-form-input-signature>
    </ng-container>

    <ng-container *ngSwitchCase="'client'">
      <app-form-input-client [field]="field"
                             [control]="formGroup.controls[field.fieldUuid]"></app-form-input-client>
    </ng-container>

    <ng-container *ngSwitchCase="'vat_search'">
      <app-form-input-vat-search [field]="field"></app-form-input-vat-search>
    </ng-container>

    <ng-container *ngSwitchCase="'post'">
      <ng-container *ngIf="hideLabel; else editField">
        <app-form-input-text [field]="field" [control]="formGroup.controls[field.fieldUuid]"></app-form-input-text>
      </ng-container>
      <ng-template #editField>
        <app-form-input-post [field]="field"
                             [controls]="formGroup.controls"
                             [postNumberControl]="formGroup.controls[field.fieldUuid]"></app-form-input-post>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'tax_number'">
      <app-form-input-tax-number [field]="field"
                                 [formGroup]="formGroup"
                                 [control]="formGroup.controls[field.fieldUuid]"></app-form-input-tax-number>
    </ng-container>

    <ng-container *ngSwitchCase="'test_plates_autocomplete'">
      <app-form-input-test-plates-autocomplete [field]="field"
                                               [formGroup]="formGroup"
                                               [controls]="formGroup.controls"
                                               [control]="formGroup.controls[field.fieldUuid]"></app-form-input-test-plates-autocomplete>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="card">
        <div class="card-content text-center text-red">{{ field.type }}</div>
      </div>
    </ng-container>
  </ng-container>

  <app-field-errors
          *ngIf="field.type !== 'repeater'"
          [control]="formGroup.controls[field.fieldUuid]"></app-field-errors>
</div>

