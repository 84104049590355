import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Field} from '@app/interfaces';
import {takeUntil} from "rxjs/operators";
import {FocusService} from "@app/services/util/focus.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-form-input-currency',
  templateUrl: './form-input-currency.component.html',
  styleUrls: ['./form-input-currency.component.scss']
})
export class FormInputCurrencyComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: AbstractControl;
  @Input() disabled = false;
  @Input() field: Field<number>;
  @Input() currencySymbol = '€';
  @Output() onKeyUp = new EventEmitter<any>();
  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;

  private lastTypeIdFromFocusService: string;
  private destroy$ = new Subject<void>();

  constructor(
    private focusService: FocusService,
  ) {
  }

  ngOnInit() {
    if (this.disabled) {
      this.control.disable();
    }
  }

  ngAfterViewInit() {
    this.focusService.getFocus()
      .pipe(takeUntil(this.destroy$))
      .subscribe((typeId) => {
        this.lastTypeIdFromFocusService = typeId;
        if (typeId === this.field.config.typeId) {
          this.inputElement?.nativeElement?.focus();
        }
      });
  }

  onKeyUpTrigger() {
    this.onKeyUp.emit(this.field.uuid);
  }

  onBlurTrigger() {
    if (this.control.value === null && this.field.config.min === 0) {
      this.control.setValue(0);
    }

    if (this.field.config.typeId === this.lastTypeIdFromFocusService) {
      this.inputElement.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
