import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SingleResult} from '../interfaces';
import {environment} from '@src/environments/environment';
import {UserApiService} from './api/user-api.service';
import {tap} from 'rxjs/operators';
import {ApiCachingService} from './api/api-caching.service';

@Injectable({
  providedIn: 'root'
})
export class LeadGenerationService {

  constructor(private http: HttpClient, private userApi: UserApiService, private cache: ApiCachingService) {
  }

  convertToFullMode() {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/users/leads/full-mode`, {}).pipe(tap((result) => {
      this.cache.clearCache();
      this.userApi.switchToNonDemo();
    }));
  }

  generateLead(payload: GenerateLeadPayload) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/users/leads`, payload);
  }

  generateLeadForOutside(extra: string, type: string, isActivation: boolean) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/users/leads-outside`, {extra, type, isActivation});
  }

  orderMotivePackages(extra: object) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/users/leads/motive-service-packages-order`, {extra});
  }

  checkIfLeadExistsForUser(referer: string) {
    return this.http.get<SingleResult<{ referrer?: string, registrationUrl: string, extra?: { isRequested: boolean } }>>(`${environment.apiUrl}/api/users/leads/${referer}`);
  }

}

export interface GenerateLeadPayload {
  extra: {
    zahteva: string,
    isActivation?: boolean,
    type?: string,
    external_email?: string,
    [key: string]: unknown
  };
}
