import {Component, HostListener, Inject, LOCALE_ID, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter, map, take} from 'rxjs/operators';
import {AuthService} from './services/auth.service';
import {AnalyticsService} from './services/analytics.service';
import {MetaService} from '@src/@ngx-meta/core/src';
import {TranslationService} from './services/translation.service';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {VersionService} from './services/version.service';
import {KeyboardService} from './services/keyboard.service';
import {SentryService} from './services/sentry.service';
import * as dayjs from 'dayjs';
import {Dayjs} from 'dayjs';
import {DateAdapter} from '@angular/material/core';
import {Platform} from '@angular/cdk/platform';
import {SafariScreenFixService} from './services/safari-screen-fix.service';
import {UserService} from './services/userService/user.service';
import {LoggingService} from '@app/services/util/logging.service';
import {ConfigAPIService} from '@app/services/api/config-api.service';
import {UserProfile} from '@app/interfaces/entities/user-profile';
import {BehaviorSubject, Observable, repeat, zip} from 'rxjs';
import {BusinessUnitsService} from './services/accounts/business-units.service';
import {isMobile} from '@app/util/browser.helper';
import {environment} from '@src/environments/environment';
import {SubscriptionExpiredDialogComponent} from '@app/dialogs/subscription-expired-dialog/subscription-expired-dialog.component';
import {AccountLockedDialogComponentComponent} from '@app/dialogs/account-locked-dialog-component/account-locked-dialog-component.component';
import {OpenProductPackageOfferDialogComponentComponent} from '@app/dialogs/open-product-package-offer-dialog-component/open-product-package-offer-dialog-component.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {productFruits} from 'product-fruits';
import { LocaleRedirectService } from './services/util/locale-redirect.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public control$: Observable<{ name: string; accountUuid: string } | null>;
  private versionCheckInSeconds = 60;
  public appReady$ = new BehaviorSubject<boolean>(false);

  public appHasError = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private metaService: MetaService,
              private authService: AuthService,
              private translation: TranslationService,
              private analyticsService: AnalyticsService,
              private versionService: VersionService,
              private keyboardService: KeyboardService,
              private sentry: SentryService,
              private dateAdapter: DateAdapter<Dayjs>,
              private platform: Platform,
              private userService: UserService,
              private safariScreenFix: SafariScreenFixService,
              private loggingService: LoggingService,
              private configService: ConfigAPIService,
              private businessUnitsService: BusinessUnitsService,
              private matDialog: MatDialog,
              private localeRedirectService: LocaleRedirectService,
              @Inject(PLATFORM_ID) private platformId: any,
              @Inject(DOCUMENT) private document,
              @Inject(LOCALE_ID) public locale: string,
  ) {

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationStart))
      .subscribe(this.loggingService.debug);

    dayjs.locale(locale);

    this.dateAdapter.setLocale(locale);
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event) {
    this.keyboardService.emit(event);
  }

  ngOnInit(): void {
    // Load initial config
    this.configService.getInitialConfig(this.locale).subscribe({
      error: (error) => {
        this.appHasError = true;
        console.error(error);
      }
    });

    // Block analytics if account is taken over - set to true by default
    this.analyticsService.setIsAccountControl(false);

    this.authService.getAccountControlChanges().subscribe((control) => {
      this.analyticsService.setIsAccountControl(!!control?.accountUuid);
    });

    // this.completed();
    this.authService.getAuthChanges().subscribe((auth) => {
      this.analyticsService.setUser(auth);

      if (auth) {
        this.analyticsService.fireBriefdAnalyticsEvent('open').subscribe();
        this.sentry.setUser(auth);
      } else {
        this.sentry.setUser(null);
      }
    });

    this.control$ = this.authService.getAccountControlChanges();
    this.control$.subscribe((control) => {
      if (control) {
        this.businessUnitsService.afterAuthChanged();
      }
    });

    this.versionService.check(true);

    this.setupPeriodicVersionChecks();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event) => {

        const title = this.extractTitle(this.activatedRoute);

        //  As the tiles are title.id, just remove the title.
        if (title) {
          this.metaService.setTitle(this.translation.getTranslation(title.slice(6, title.length)));
        }

        // @ts-ignore
        this.analyticsService.firePageEvent(event.urlAfterRedirects, title);

        return title;
      })
    ).subscribe();

    //  Refresh the cookie for the logged in user, just in case it's not valid anymore
    if (this.authService.isLoggedIn()) {
      this.authService.setupCookieForEasierImageLoading().subscribe();

      this.configService.getAccountConfig().pipe(take(1))
        .subscribe((config) => {
          this.analyticsService.setAccountConfig(config);
        });
    }

    zip(this.configService.getAccountConfig(), this.userService.getUserProfile()).subscribe(([config, user]) => {
      if (user) {
        if (!environment.product_fruits.code.startsWith('x')) {
          (window as any).productFruits = (window as any).productFruits || productFruits;
          (window as any).productFruits.init(environment.product_fruits.code, user.originalLocale, {
            username: user.email,
            email: user.email,
            firstname: user.profile?.firstName,
            lastname: user.profile?.lastName,
            signUpAt: user.createdAt,
            props: {
              deviceType: isMobile() ? 'mobile' : 'desktop',
              isStaging: environment.staging,
              isProduction: environment.production,
              activeFeatureFlags: this.convertBooleanObjectToArray(config.accountFeatureFlags),
              activeIntegrations: config.accountIntegrations.filter(integration => integration.isActive).map(integration => integration.type),
              activeProductPackage: config.activeAccountProductPackage.productPackage.name,
              isTrial: config.activeAccountProductPackage.isTrial,
              isTrialExtended: config.activeAccountProductPackage.isTrialExtended,
              subscriptionType: config.activeAccountProductPackage.subscriptionType,
              // permissions: this.convertBooleanObjectToArray(config.permissions),
              accountPreferencesPartner: config.accountPreferences?.partner ?? null,
            }
          },
            {
              hideInAppCenterLauncher: true
            });
        }

        this.businessUnitsService.afterAuthChanged();

        if (environment.production) {
          this.localeRedirectService.compareUserLocaleVsSiteLocaleAndRedirect(user);
        }
      }

      this.appReady$.next(true);
    });

    // Check if Package subscription is expired
    this.configService.getAccountConfig().pipe(repeat({delay: 3600 * 1000})).subscribe((config) => {
      if (config.isDeactivated) {
        this.authService.logout();
        this.router.navigate(['/deactivated']);
      } else if (config.activeAccountProductPackage?.isExpired === undefined || config.activeAccountProductPackage.isExpired) {
        SubscriptionExpiredDialogComponent.open(this.matDialog, config);
      } else if (config.isLocked) {
        AccountLockedDialogComponentComponent.open(this.matDialog, config);
      } else if (!config.activeAccountProductPackage?.isTrial && (!config.activeAccountProductPackage?.isSigned && !config.activeAccountProductPackage?.isRejected && config.activeAccountProductPackage.paymentUrl)) {
        OpenProductPackageOfferDialogComponentComponent.open(this.matDialog, config);
      }
    });
  }

  private convertBooleanObjectToArray(obj: { [key: string]: boolean }): string[] {
    return Object.keys(obj).filter(key => obj[key] === true);
  }

  private extractTitle(activatedRoute: ActivatedRoute): any {
    let title: any = null;
    let child = activatedRoute.firstChild;

    if (child.snapshot.data.title) {
      title = child.snapshot.data.title;
    }

    //  We might have a more complex route, try to show the title of the "last" link in the path
    while (child.children.length > 0) {
      child = child.firstChild;
      if (child.snapshot.data.title) {
        title = child.snapshot.data.title;
      }
    }

    return title;
  }

  releaseControl() {
    this.authService.takeControl(null);

    window.location.reload();
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:load', ['$event'])
  getScreenSize(event?) {
    this.safariScreenFix.setIsSafari(this.platform.SAFARI || this.platform.IOS);
    if (!this.safariScreenFix.getIsSafari()) {
      const vh = window.innerHeight * 0.01;
      this.document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }

  private setupPeriodicVersionChecks() {
    if (isPlatformBrowser(this.platformId)) {
      setInterval(() => {
        this.versionService.check();
      }, this.versionCheckInSeconds * 1000);
    }
  }
}
