<div class="relative">
  <input type="number"
         lang="en-150"
         step='0.01'
         [formControl]="control"
         [attr.aria-label]="field.config.label"
         [ngClass]="{ 'input-error': control.invalid }"
         (keyup)="onKeyUpTrigger()"
         #inputElement
         (blur)="onBlurTrigger()"
         [attr.data-field]="field?.fieldUuid"
         [attr.data-typeId]="field?.config?.typeId"
         placeholder="{{ field.config.placeholder }}"
         autocomplete="off"
         [attr.tabIndex]="0"
  >

  <span class="absolute right-0 mr-8 z-20 pointer-events-none text-xs text-grayDark"
        style="margin-top: 0.85rem;">{{currencySymbol}}</span>
</div>
