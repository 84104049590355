import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {environment} from "@src/environments/environment";

declare global {
  interface Window {
    OpenWidget: any;
    productFruits: any;
    productFruitsReady: any;
    __ow: any;
  }
}

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  standalone: true,
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit {
  private static widgetLoaded: boolean = false;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT)
    private document: Document) {
  }

  ngOnInit(): void {
    if (!environment.chatBot?.enabled) {
      return;
    }

    if (!ChatBotComponent.widgetLoaded) {
      ChatBotComponent.widgetLoaded = true;
      this.loadChatbotScripts();
    }


    if (window.OpenWidget && typeof window.OpenWidget.on === 'function') {
      window.productFruitsReady = function () {
        let count: number = 0;
        window.OpenWidget.on('ready', function () {
          window.OpenWidget.call('hide');
        });

        window.OpenWidget.on('visibility_changed', function (data) {
          if (count > 0) {
            if (data.visibility === 'maximized') {
              window.productFruits.api.inAppCenter.hide();
            } else if (data.visibility === 'minimized') {
              window.OpenWidget.call('hide');
              window.productFruits.api.inAppCenter.show();
            }
            count++;
          }
        })

        window.addEventListener('productfruits_button_ext_widget_init', function () {
          window.OpenWidget.call('maximize');
          count++;
        });
      }
    }
  }

  /** Injects the ChatBot (OpenWidget) script and sets up the required global variables */
  private loadChatbotScripts(): void {
    const w = window as any;
    w.__ow = w.__ow || {};
    w.__ow.organizationId = environment.chatBot.organizationId;
    w.__ow.template_id = environment.chatBot.template_id;
    w.__ow.integration_name = environment.chatBot.integration_name;
    w.__ow.product_name = environment.chatBot.product_name;

    function x(n, t, c) {

      function i(n) {
        return e._h ? e._h.apply(null, n) : e._q.push(n)
      }

      var e = {
        _q: [], _h: null, _v: "2.0", on: function () {
          i(["on", c.call(arguments)])
        }, once: function () {
          i(["once", c.call(arguments)])
        }, off: function () {
          i(["off", c.call(arguments)])
        }, get: function () {
          if (!e._h) throw new Error("[OpenWidget] You can't use getters before load.");
          return i(["get", c.call(arguments)])
        }, call: function () {
          i(["call", c.call(arguments)])
        }, init: function () {
          var n = t.createElement("script");
          n.async = !0, n.type = "text/javascript", n.src = "https://cdn.openwidget.com/openwidget.js", t.head.appendChild(n)
        }
      };
      !n.__ow.asyncInit && e.init(), n.OpenWidget = n.OpenWidget || e
    }

    x(window, document, [].slice);

    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.openwidget.com/openwidget.js';
    script.async = true;

    this.renderer.listen(script, 'load', () => {
      if (window.OpenWidget && typeof window.OpenWidget.call === 'function') {
        window.OpenWidget.call('hide');
      }
    });

    this.renderer.appendChild(this.document.head, script);
  }
}
