import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ArrayResult} from '../interfaces/responses/array-result';
import {Integration} from '../interfaces/entities/integration';
import {environment} from '@src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PaginatedResult, SingleResult, Vehicle} from '../interfaces';
import {ApiCachingService} from '@app/services/api/api-caching.service';
import {Leasing} from '@app/interfaces/entities/Leasing';
import {NlbLeasingIframeData} from '../interfaces/integration/NlbLeasingIframeData.interface';
import {map} from 'rxjs/operators';
import {ImportedVehicleFormResult} from '@app/interfaces/form/imported-vehicle-form-result';
import {ApiHelperService} from '@app/util/api-helper.service';
import {PaginationFilter} from '@app/interfaces/pagination/paginationFilter';
import {AccountIntegration} from '@app/interfaces/entities/accounts/AccountIntegration';
import {AdvertisingForm} from '@app/interfaces/entities/advertising-form';
import {IntegrationVehicleAd} from "@app/interfaces/advertising/IntegrationVehicleAd";

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(private http: HttpClient, private cacheApi: ApiCachingService) {
  }

  getIntegrations(accountUuid: string = null, resetCache = false, businessUnitUuid: string = null, isSettings: boolean = false): Observable<ArrayResult<Integration>> {
    let params = {};

    if (accountUuid) {
      params = {accountUuid: accountUuid};
    }

    if (businessUnitUuid) {
      params['businessUnitUuid'] = businessUnitUuid;
    }

    if (isSettings) {
      params['isSettings'] = 'true';
    }

    const cacheKey = accountUuid + (businessUnitUuid ?? '') + (isSettings ? 'settings' : 'notsettings') + 'integrations';

    if (resetCache) {
      this.cacheApi.clearCached(cacheKey);
    }

    return this.cacheApi.getCached(cacheKey, this.http.get<ArrayResult<Integration>>(`${environment.apiUrl}/api/integrations`, {params}));
  }

  getIntegration(integration: string): Observable<Integration | undefined> {
    return this.getIntegrations().pipe(
      map((response) => response.data.find((item) => item.type.toLowerCase() === integration.toLowerCase()))
    );
  }

  getSpaceInvoicesIntegration(): Observable<SingleResult<any>> {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/integrations/space_invoices/`);
  }

  setupSpaceInvoicesWebhook(): Observable<SingleResult<any>> {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/space_invoices/setup-webhook`, {});
  }

  setupSpaceInvoices(accountUuid: string = null): Observable<SingleResult<any>> {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/super-admin/integrations/space_invoices/setup`, {accountUuid});
  }

  syncSpaceInvoices(): Observable<SingleResult<any>> {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/space_invoices/sync`, {});
  }

  issueSpaceInvoiceDocument(type: string, clientUuid: string, vehicleUuids: string[]) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/space_invoices/issue-document`, {
      type,
      clientUuid,
      vehicleUuids
    });
  }

  storeAd(type: string, vehicleUuid: string, data: { [key: string]: any }) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/${type}/${vehicleUuid}`, data);
  }

  unpublishAd(type: string, vehicleUuid: string) {
    return this.http.delete<SingleResult<any>>(`${environment.apiUrl}/api/integrations/${type}/${vehicleUuid}`);
  }

  activateIntegrationForAccount(integrationType: string, accountUuid: string = null, businessUnitUuid: string = null) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/super-admin/integrations/${integrationType}/activate`, {accountUuid, businessUnitUuid});
  }

  deactivateIntegrationForAccount(integrationType: string, accountUuid: string = null, businessUnitUuid: string = null) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/super-admin/integrations/${integrationType}/deactivate`, {accountUuid, businessUnitUuid});
  }


  updateIntegrationConfigForAccount(integrationType: string, accountUuid: string = null, businessUnitUuid: string = null) {
    return this.http.put<SingleResult<any>>(`${environment.apiUrl}/api/super-admin/integrations/${integrationType}`, {accountUuid, businessUnitUuid});
  }

  setupAvtoNet(accountUuid: string = null) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/super-admin/integrations/avtonet/setup`, {accountUuid});
  }

  getVehicleAdForIntegration(type: string, vehicleUuid: string) {
    return this.http.get<SingleResult<IntegrationVehicleAd>>(`${environment.apiUrl}/api/integrations/${type}/${vehicleUuid}`);
  }

  getIntegrationTypesWithVehicleAds(vehicleUuid: string) {
    return this.http.get<any>(`${environment.apiUrl}/api/integrations/ads/${vehicleUuid}`);
  }

  getIntegrationVehicleAds(vehicleUuid: string) {
    return this.http.get<ArrayResult<IntegrationVehicleAd>>(`${environment.apiUrl}/api/integrations/all-ads/${vehicleUuid}`);
  }

  bulkPublishVehicleIntegrationAds(integrationType: string, vehicleUuids: string[]) {
    return this.http.post<SingleResult<string[]>>(`${environment.apiUrl}/api/integrations/${integrationType}/bulk-publish`, {vehicleUuids});
  }

  getPaginatedLeasings(type: string, sort: string, order: string, page: number, perPage: number, filter: any = {}):
    Observable<PaginatedResult<any[]>> {
    let params = new HttpParams();
    if (sort) {
      params = params.append('sortBy', sort.toString());

      if (order) {
        params = params.append('sortOrder', order.toString());
      }
    }
    params = params.append('page', page.toString());
    params = params.append('perPage', perPage.toString());

    if (type) {
      params = params.append('type', type);
    }

    for (const prop in filter) {
      if (filter.hasOwnProperty(prop)) {
        if (Array.isArray(filter[prop])) {
          filter[prop].forEach((item) => {
            params = params.append(prop + '[]', item.toString());
          });
        } else if (filter[prop]) {
          params = params.append(prop, filter[prop].toString());
        }
      }
    }

    return this.http.get<PaginatedResult<any[]>>(`${environment.apiUrl}/api/integrations/nlb/leasing`, {params});
  }

  getClientLeasings(clientUuid: string): Observable<PaginatedResult<any[]>> {
    return this.http.get<PaginatedResult<any[]>>(`${environment.apiUrl}/api/integrations/nlb/leasing/${clientUuid}`);
  }

  getVehicleLeasings(vehicleUuid: string): Observable<PaginatedResult<Leasing[]>> {
    return this.http.get<PaginatedResult<Leasing[]>>(`${environment.apiUrl}/api/integrations/nlb/leasing/vehicle/${vehicleUuid}`);
  }

  getVehicleLeasingDrafts(vehicleUuid: string): Observable<PaginatedResult<any[]>> {
    return this.http.get<PaginatedResult<any[]>>(`${environment.apiUrl}/api/integrations/nlb/leasing-drafts/vehicle/${vehicleUuid}`);
  }

  getPDFforLeasing(leasingUuid: string, type: boolean): Observable<any> {
    // @ts-ignore
    return this.http.post<PaginatedResult<any[]>>(`${environment.apiUrl}/api/integrations/nlb/leasing/pdf/${leasingUuid}/${type}`, {}, {responseType: 'blob'});
  }

  prefillPDFForLeasing(companyType: string, vehicleUuid: string, clientUuid: string, draftUuid?: string, price?, payments?, periods?): Observable<any> {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/nlb/prefill-pdf`, {
      companyType,
      vehicleUuid,
      clientUuid,
      price,
      payments,
      periods,
      draftUuid
      // @ts-ignore
    }, {responseType: 'blob'});
  }

  getNlbLeasingIframe(data: NlbLeasingIframeData) {
    return this.http.post<{ link: string }>(`${environment.apiUrl}/api/integrations/nlb/iframe-callback`, data);
  }

  getVehicleAdFormForIntegration(type: string, vehicleUuid: string) {
    return this.http.get<SingleResult<AdvertisingForm>>(`${environment.apiUrl}/api/integrations/${type}/${vehicleUuid}/form`);
  }

  updateAccountIntegrationConfig(integrationUuid: string, config: {}, accountUuid: string, businessUnitUuid: string = null): Observable<SingleResult<AccountIntegration>> {
    return this.http.put<SingleResult<any>>(`${environment.apiUrl}/api/super-admin/account-integrations/${integrationUuid}`, {config, accountUuid, businessUnitUuid});
  }

  updateAccountIntegrationConfigNonAdmin(platform: string, config: Record<string, unknown>, businessUnitUuid: string = null): Observable<SingleResult<any>> {
    return this.http.put<SingleResult<any>>(`${environment.apiUrl}/api/account-integrations/${platform}`, {config, businessUnitUuid});
  }

  getPaginatedWarranties(type: string, sort: string, order: string, page: number, perPage: number, filter: any = {}):
    Observable<PaginatedResult<any[]>> {
    let params = new HttpParams();
    if (sort) {
      params = params.append('sortBy', sort.toString());

      if (order) {
        params = params.append('sortOrder', order.toString());
      }
    }
    params = params.append('page', page.toString());
    params = params.append('perPage', perPage.toString());

    if (type) {
      params = params.append('type', type);
    }

    for (const prop in filter) {
      if (filter.hasOwnProperty(prop)) {
        if (Array.isArray(filter[prop])) {
          filter[prop].forEach((item) => {
            params = params.append(prop + '[]', item.toString());
          });
        } else if (filter[prop]) {
          params = params.append(prop, filter[prop].toString());
        }
      }
    }

    return this.http.get<PaginatedResult<any[]>>(`${environment.apiUrl}/api/integrations/motive_service/warranties/show`, {params});
  }

  showMotiveServiceWarranties(params: { km: number; starost: number; ccm: number }) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/motive_service/warranties`, params);
  }

  showPurchasedMotiveServiceWarranties() {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/integrations/motive_service/warranties/show-purchased`);
  }

  showMotiveServiceWarrantyForm(vehicleUuid: string, clientUuid: string, warrantyTitle: string) {
    let params = new HttpParams();
    if (clientUuid) {
      params = params.append('clientUuid', clientUuid.toString());
    }
    if (warrantyTitle) {
      params = params.append('warranty', warrantyTitle.toString());
    }

    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/integrations/motive_service/warranties/vehicles/${vehicleUuid}`, {params});
  }

  issueMotiveServiceWarranty(vehicleUuid: string, clientUuid: string, values: { [p: string]: any }) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/motive_service/warranties/issue`, {
      vehicleUuid,
      clientUuid,
      ...values
    });
  }

  issueNlbLeasingDocument(vehicleUuid: string, clientUuid: string, values: { [p: string]: any }, formType: string, data: any, formVersion: string, companyType?: string, draftUuid?: string, draft?) {
    let params = new HttpParams();
    params = params.append('formType', formType);
    params = params.append('installment', data.installment);
    params = params.append('costs', data.costs);
    params = params.append('rate', data.rate);
    params = params.append('package', data.package);
    params = params.append('periods', data.period);
    params = params.append('price', data.price);
    params = params.append('payments', data.payments);
    params = params.append('typeOfForm', formVersion);

    if (companyType) {
      params = params.append('companyType', companyType);
    }

    if (draftUuid) {
      params = params.append('draftUuid', draftUuid);
    }

    if (draft) {
      params = params.append('sendDraft', draft);
    }

    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/nlb/leasing/issue`, {
      vehicleUuid,
      clientUuid,
      ...values
    }, {params});

  }

  saveNlbDraft(vehicleUuid: string, clientUuid: string, values: { [p: string]: any }, data: any, type: string, formType: string, draftUuid?: string) {
    let params = new HttpParams();
    params = params.append('installment', data.installment);
    params = params.append('costs', data.costs);
    params = params.append('rate', data.rate);
    params = params.append('package', data.package);
    params = params.append('periods', data.period);
    params = params.append('payments', data.payments);
    params = params.append('price', data.price);
    params = params.append('typeOfForm', type);

    if (data.companyType) {
      params = params.append('companyType', data.companyType);
    }

    if (draftUuid) {
      params = params.append('draftUuid', draftUuid);
    }

    params = params.append('formType', formType);

    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/nlb/leasing/save-draft`, {
      vehicleUuid,
      clientUuid,
      ...values
    }, {params});

  }

  deleteNlbDraft(draftUuid: string) {
    let params = new HttpParams();
    params = params.append('draftUuid', draftUuid);

    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/nlb/leasing/delete-draft`, {}, {params});
  }

  saveInfoCalculation(vehicleUuid: string = null, data = null, isPrinting = false) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/nlb/leasing/save-info-calculation`, {
      vehicleUuid,
      data,
      isPrinting
    });
  }

  getMotiveServiceWarranty(vehicleUuid: any) {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/integrations/motive_service/warranties/vehicles/${vehicleUuid}/warranty`);
  }

  downloadMotiveServicePDF(warrantyId: string) {
    return this.http.post(`${environment.apiUrl}/api/integrations/motive_service/warranties/pdf/${warrantyId}`, {}, {responseType: 'blob'});
  }

  deactivateSpaceInvoices(accountUuid: string = null) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/super-admin/integrations/space_invoices/deactivate`, {accountUuid});
  }

  activateSpaceInvoices(accountUuid: string = null) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/super-admin/integrations/space_invoices/activate`, {accountUuid});
  }

  showNlbLeasingForm(vehicleUuid: string, clientUuid: string, data, formType: string, draft?: string, draftUuid?: string) {
    let params = new HttpParams();
    if (clientUuid) {
      params = params.append('clientUuid', clientUuid.toString());
    }

    if (draft) {
      params = params.append('draft', draft);
    }

    if (draftUuid) {
      params = params.append('draftUuid', draftUuid);
    }

    if (data) {
      params = params.append('payments', data.payments);
      params = params.append('price', data.price);
      params = params.append('periods', data.period);
      params = params.append('package', data.package);
      params = params.append('companyType', data.companyType);
      params = params.append('installment', data.installment);
      params = params.append('costs', data.costs);
      params = params.append('rate', data.rate);
    }

    params = params.append('formType', formType);

    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/integrations/nlb/leasing/vehicles/${vehicleUuid}`, {params});
  }

  getPaginatedAuctionsVehicles(paginationFilter: PaginationFilter): Observable<PaginatedResult<any[]>> {
    let params = ApiHelperService.buildPaginationParams(paginationFilter);

    return this.http.get<PaginatedResult<any[]>>(`${environment.apiUrl}/api/integrations/amp/get-vehicles`, {params});
  }

  getAMPImportForm(vehicleUuid: string) {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/integrations/amp/import-vehicle/${vehicleUuid}`);
  }

  getImportVehicleForm(vehicleUuid: string, vinNumber: string) {
    return this.http.post<SingleResult<ImportedVehicleFormResult>>(`${environment.apiUrl}/api/integrations/vehicle-import/import-vehicle/${vehicleUuid}`, {vinNumber});
  }

  storeAMPVehicle(fields: { [key: string]: any }, vehicleVin): Observable<SingleResult<Vehicle>> {
    let params = new HttpParams();
    params = params.append('vehicleVin', vehicleVin);

    return this.http.post<SingleResult<Vehicle>>(`${environment.apiUrl}/api/integrations/amp/save-vehicle`, {...fields}, {params});
  }

  createNewVehicleFromImportedVehicle(fields: { [p: string]: string | number | null }, importedVehicleUuid): Observable<SingleResult<Vehicle>> {
    let params = new HttpParams();
    params = params.append('importedVehicleUuid', importedVehicleUuid);

    return this.http.post<SingleResult<Vehicle>>(`${environment.apiUrl}/api/integrations/vehicle-import/save-vehicle`, {...fields}, {params});
  }

  updateVehicleFromImportedVehicle(vin: string, fields: { [key: string]: string | number | null }, importedVehicleUuid): Observable<SingleResult<Vehicle>> {
    let params = new HttpParams();
    params = params.append('importedVehicleUuid', importedVehicleUuid);

    return this.http.post<SingleResult<Vehicle>>(`${environment.apiUrl}/api/integrations/vehicle-import/update-vehicle`, {...fields}, {params});
  }

  uploadExistingFilesToNlb(fileUuids: Array<string>): Observable<any> {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/nlb/leasing/upload-existing-files`, {fileUuids});
  }

  createVehicleFromVinAndTrimId(vinNumber: string, trimId: string): Observable<SingleResult<Vehicle>> {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/dober_avto/create-vehicle-from-vin`, {
      vinNumber,
      trimId
    });
  }

  getNlbLeasingPDF(data: {}): Observable<Blob> {
    // @ts-ignore
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/nlb/print-calculation`, {data}, {responseType: 'blob'});
  }

  requestNjuskaloContact() {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/njuskalo/request-contact`, {});
  }

  requestIndexContact() {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/integrations/index_hr/request-contact`, {});
  }

  loginToDoberAvto() {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/login/doberavto`, {});
  }

  removeDoberAvtoConnection(connectionUuid: string) {
    return this.http.delete<SingleResult<any>>(`${environment.apiUrl}/api/logout/doberavto/${connectionUuid}`);
  }
}
