import { Component, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-support-center-button',
  templateUrl: './support-center-button.component.html',
  styleUrls: ['./support-center-button.component.scss']
})
export class SupportCenterButtonComponent implements AfterViewInit {
  @Input() buttonId: string = 'customLifeRingLauncher';
  private isProductFruitsReady = false;

  ngAfterViewInit() {
    this.attachProductFruits();
  }

  onButtonClick() {
    const button = document.getElementById(this.buttonId);

    if (!button) {
      return;
    }

    if (!this.isProductFruitsReady) {
      this.attachProductFruits(button);
    }

    this.openProductFruits();
  }

  private attachProductFruits(button?: HTMLElement) {
    if (this.isProductFruitsReady) return;

    button = button || document.getElementById(this.buttonId);
    if (!button) {
      return;
    }

    if ((window as any).productFruits?.api?.inAppCenter?.attachToCustomElement) {
      (window as any).productFruits.api.inAppCenter.attachToCustomElement(button);
      this.isProductFruitsReady = true;
    }
  }

  private openProductFruits() {
    if ((window as any).productFruits?.api?.inAppCenter?.open) {
      (window as any).productFruits.api.inAppCenter.open();
    }
  }
}