import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FocusService {

  private focusFieldSubject = new ReplaySubject<string>();

  setFocus(fieldType: string) {
    this.focusFieldSubject.next(fieldType);
  }

  getFocus(): Observable<string> {
    return this.focusFieldSubject.asObservable();
  }
}
