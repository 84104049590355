import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {ImagePlaceholderComponent} from './image-placeholder/image-placeholder.component';
import {NotFoundErrorComponent} from './not-found-error/not-found-error.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {TranslatePlaceholdersComponent} from './translate-placeholders/translate-placeholders.component';
import {RouterModule} from '@angular/router';
import {AutocompleteClientComponent} from './partials/autocomplete-client/autocomplete-client.component';
import {AutocompleteClientBusinessUnitComponent} from './partials/autocomplete-client-business-unit/autocomplete-client-business-unit.component';
import {AutocompleteVehicleComponent} from './partials/autocomplete-vehicle/autocomplete-vehicle.component';
import {ClientCardComponent} from './partials/client-card/client-card.component';
import {ClientTableCellComponent} from './partials/client-table-cell/client-table-cell.component';
import {ColorComponent} from './partials/color/color.component';
import {CreateAdministrationDocumentButtonComponent} from './partials/create-administration-document-button/create-administration-document-button.component';
import {FieldFormComponent} from './partials/field-form/field-form.component';
import {EntityGroupComponent} from './partials/entity-group/entity-group.component';
import {FieldFormInputsComponent} from './partials/field-form-inputs/field-form-inputs.component';
import {FieldTableValueComponent} from './partials/field-table-value/field-table-value.component';
import {IdentificationComponent} from './partials/identification/identification.component';
import {ContentShimmerComponent} from './partials/shimmers/content-shimmer/content-shimmer.component';
import {ShimmerComponent} from './partials/shimmers/shimmer/shimmer.component';
import {TableShimmerComponent} from './partials/shimmers/table-shimmer/table-shimmer.component';
import {PaginatorComponent} from './partials/table/paginator/paginator.component';
import {VehicleComponent} from './partials/vehicle/vehicle.component';
import {VehicleCardComponent} from './partials/vehicle-card/vehicle-card.component';
import {VehicleIdentificationTableCellComponent} from './partials/vehicle-identification-table-cell/vehicle-identification-table-cell.component';
import {VehicleModelAutocompleteComponent} from './partials/vehicle-model-autocomplete/vehicle-model-autocomplete.component';
import {VehicleTableCellComponent} from './partials/vehicle-table-cell/vehicle-table-cell.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CdkTableModule} from '@angular/cdk/table';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ShareVehicleWidgetComponent} from './share-vehicle-widget/share-vehicle-widget.component';
import {FormShimmerComponent} from './partials/shimmers/form-shimmer/form-shimmer.component';
import {FormSpinnerComponent} from './form-spinner/form-spinner.component';
import {FormTabsComponent} from './form-tabs/form-tabs.component';
import {TooltipComponent} from './tooltip/tooltip.component';
import {VehicleAutocompleteMarketComponent} from './partials/vehicle-autocomplete-market/vehicle-autocomplete-market.component';
import {VehicleCardSmallComponent} from './partials/vehicle-card-small/vehicle-card-small.component';
import {PrefillTemplateCardComponent} from './prefill-template-card/prefill-template-card.component';
import {PrefillTemplateCellComponent} from './prefill-template-cell/prefill-template-cell.component';
import {VehicleNameComponent} from './vehicle/vehicle-name/vehicle-name.component';
import {FilesTableComponent} from './files-table/files-table.component';
import {FileTableValueComponent} from './files-table-value/file-table-value.component';
import {NumberingItemComponent} from './numbering-item/numbering-item.component';
import {AdministrationSidebarComponent} from './administration-sidebar/administration-sidebar.component';
import {FieldErrorsComponent} from './field-errors/field-errors.component';
import {IfAccountFeatureFlagDirective} from './directives/if-account-feature-flag.directive';
import {AutocompleteSpaceInvoicesDocumentComponent} from './partials/autocomplete-space-invoices-document/autocomplete-space-invoices-document.component';
import {DocumentStatusComponent} from './partials/document-status/document-status.component';
import {IfIntegrationOnDirective} from './directives/if-integration-on.directive';
import {BottomDrawerComponent} from './bottom-drawer/bottom-drawer.component';
import {LeadContactUsComponent} from './lead-contact-us/lead-contact-us.component';
import {HelpContactUsComponent} from './help-contact-us/help-contact-us.component';
import {IfIntegrationOffDirective} from './directives/if-integration-off.directive';
import {MotiveServicePromoCardComponent} from './motive-service-promo-card/motive-service-promo-card.component';
import {MotiveServiceIssueWarrantyCardComponent} from './motive-service-issue-warranty-card/motive-service-issue-warranty-card.component';
import {NlbLeasePromoCardComponent} from './nlb-lease-promo-card/nlb-lease-promo-card.component';
import {NlbLeaseGoUxPromoCardComponent} from './nlb-lease-go-ux-promo-card/nlb-lease-go-ux-promo-card.component';
import {LeasingStatusComponent} from './leasing-status/leasing-status.component';
import {VehicleStatusComponent} from './vehicle/vehicle-status/vehicle-status.component';
import {LeadContactOtherComponent} from './lead-contact-other/lead-contact-other.component';
import {AmpStatusComponent} from './amp-status/amp-status.component';
import {OverviewCardComponent} from './cards/overview-card/overview-card.component';
import {VehicleBrandAutocompleteComponent} from './partials/vehicle-brand-autocomplete/vehicle-brand-autocomplete.component';
import {NgChartsModule} from 'ng2-charts';
import {OverviewCardWrapperComponent} from './cards/overview-card-wrapper/overview-card-wrapper.component';
import {ChartComponent} from './charts/chart-component/chart-component.component';
import {VehicleCreateByVinComponent} from './vehicle-create-by-vin/vehicle-create-by-vin.component';
import {PipesModule} from '../pipes/pipes.module';
import {NotificationsModule} from '@app/modules/notifications/notifications.module';
import {ClientCardSimpleComponent} from './partials/client-card-simple/client-card-simple.component';
import {GlobalSearchBarComponent} from './global-search-bar/global-search-bar.component';
import {GlobalSearchResultTypeCardComponent} from './global-search-result-card/global-search-result-type-card.component';
import {GlobalSearchResultCardClientComponent} from './global-search-result-card-client/global-search-result-card-client.component';
import {GlobalSearchResultCardVehicleComponent} from './global-search-result-card-vehicle/global-search-result-card-vehicle.component';
import {GlobalSearchResultCardDocumentComponent} from './global-search-result-card-document/global-search-result-card-document.component';
import {GlobalSearchResultCardInvoiceComponent} from './global-search-result-card-invoice/global-search-result-card-invoice.component';
import {AssetTableCellComponent} from './partials/asset-table-cell/asset-table-cell.component';
import {AutocompleteUserComponent} from './partials/autocomplete-user/autocomplete-user.component';
import {ShowVehicleHistoryComponent} from '@app/partials/show-vehicle-history/show-vehicle-history.component';
import {LeanPayPromoCardComponent} from './lean-pay-promo-card/lean-pay-promo-card.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {TextInputComponent} from './inputs/text-input/text-input.component';
import {NumberInputComponent} from './inputs/number-input/number-input.component';
import {DropdownInputComponent} from './inputs/dropdown-input/dropdown-input.component';
import {DateInputComponent} from './inputs/date-input/date-input.component';
import {PhoneNumberInputComponent} from './inputs/phone-number-input/phone-number-input.component';
import {TextMaskModule} from '@myndmanagement/text-mask';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MaskedTextInputComponent} from './inputs/masked-text-input/masked-text-input.component';
import {PostInputComponent} from './inputs/post-input/post-input.component';
import {PartnersPortalGenericPromoCardComponent} from '@app/partials/partners-portal-generic-promo-card/partners-portal-generic-promo-card.component';
import {LeadPartnersPortalComponent} from '@app/partials/lead-partners-portal/lead-partners-portal.component';
import {PackageCardComponent} from './package-card/package-card.component';
import {InvoiceLimitCardComponent} from './invoice-limit-card/invoice-limit-card.component';
import {RegistrationPageProductPackageFeaturesComponent} from '@app/partials/registration-page-product-package-features/registration-page-product-package-features.component';
import {VehicleCardPresentationComponent} from './partials/vehicle-card-presentation/vehicle-card-presentation.component';
import {CancelSubscriptionFeedbackComponent} from './cancel-subscription-feedback/cancel-subscription-feedback.component';
import {VerticalShimmerComponent} from "@app/partials/partials/shimmers/grid-shimmer/vertical-shimmer.component";
import {IfPermissionDirective} from './directives/if-permission.directive';
import {IfAccountCountryDirective} from "@app/partials/directives/if-account-country.directive";
import {AutocompleteAnyDocumentComponent} from "@app/partials/partials/autocomplete-any-document/autocomplete-any-document.component";
import { AddikoPromoCardComponent } from './addiko-promo-card/addiko-promo-card.component';
import { IfStagingEnvironmentDirective } from './directives/if-staging-environment.directive';
import { EmailInputComponent } from './inputs/email-input/email-input.component';
import { DropdownSearchInputComponent } from './inputs/dropdown-search-input/dropdown-search-input.component';
import { CheckboxInputComponent } from './inputs/checkbox-input/checkbox-input.component';
import { VehicleAdvertisingLogosComponent } from './vehicle-advertising-logos/vehicle-advertising-logos.component';
import {AdvertisingTableComponent} from "@app/partials/advertising-table/advertising-table.component";
import {MatSortModule} from "@angular/material/sort";
import { SupportCenterButtonComponent } from './partials/support-center-button/support-center-button.component';

@NgModule({
  providers: [
    TextMaskModule
  ],
  declarations: [
    NavbarComponent,
    SidebarComponent,
    ImagePlaceholderComponent,
    NotFoundErrorComponent,
    SpinnerComponent,
    TranslatePlaceholdersComponent,
    AutocompleteClientBusinessUnitComponent,
    AutocompleteClientComponent,
    AutocompleteVehicleComponent,
    ClientCardComponent,
    ClientCardSimpleComponent,
    ClientTableCellComponent,
    ColorComponent,
    CreateAdministrationDocumentButtonComponent,
    EntityGroupComponent,
    FieldFormComponent,
    FieldFormInputsComponent,
    FieldTableValueComponent,
    IdentificationComponent,
    ContentShimmerComponent,
    ShimmerComponent,
    TableShimmerComponent,
    PaginatorComponent,
    VehicleComponent,
    VehicleCardComponent,
    NlbLeaseGoUxPromoCardComponent,
    VehicleIdentificationTableCellComponent,
    VehicleModelAutocompleteComponent,
    VehicleTableCellComponent,
    ShareVehicleWidgetComponent,
    FormShimmerComponent,
    FormSpinnerComponent,
    FormTabsComponent,
    TooltipComponent,
    VehicleAutocompleteMarketComponent,
    VehicleCardSmallComponent,
    PrefillTemplateCardComponent,
    PrefillTemplateCellComponent,
    VehicleNameComponent,
    FilesTableComponent,
    FileTableValueComponent,
    NumberingItemComponent,
    AdministrationSidebarComponent,
    FieldErrorsComponent,
    IfAccountFeatureFlagDirective,
    AutocompleteSpaceInvoicesDocumentComponent,
    DocumentStatusComponent,
    IfIntegrationOnDirective,
    BottomDrawerComponent,
    LeadContactUsComponent,
    HelpContactUsComponent,
    IfIntegrationOffDirective,
    MotiveServicePromoCardComponent,
    VehicleBrandAutocompleteComponent,
    MotiveServiceIssueWarrantyCardComponent,
    NlbLeasePromoCardComponent,
    LeasingStatusComponent,
    VehicleStatusComponent,
    LeadContactOtherComponent,
    AmpStatusComponent,
    OverviewCardComponent,
    OverviewCardWrapperComponent,
    OverviewCardWrapperComponent,
    ChartComponent,
    VehicleCreateByVinComponent,
    GlobalSearchBarComponent,
    GlobalSearchResultTypeCardComponent,
    GlobalSearchResultCardClientComponent,
    GlobalSearchResultCardVehicleComponent,
    GlobalSearchResultCardDocumentComponent,
    GlobalSearchResultCardInvoiceComponent,
    AutocompleteUserComponent,
    ShowVehicleHistoryComponent,
    LeanPayPromoCardComponent,
    TextInputComponent,
    NumberInputComponent,
    DropdownInputComponent,
    DateInputComponent,
    PhoneNumberInputComponent,
    AssetTableCellComponent,
    MaskedTextInputComponent,
    PostInputComponent,
    PartnersPortalGenericPromoCardComponent,
    LeadPartnersPortalComponent,
    PackageCardComponent,
    InvoiceLimitCardComponent,
    RegistrationPageProductPackageFeaturesComponent,
    CancelSubscriptionFeedbackComponent,
    VehicleCardPresentationComponent,
    VerticalShimmerComponent,
    IfPermissionDirective,
    IfAccountCountryDirective,
    AutocompleteAnyDocumentComponent,
    AddikoPromoCardComponent,
    IfStagingEnvironmentDirective,
    EmailInputComponent,
    DropdownSearchInputComponent,
    CheckboxInputComponent,
    VehicleAdvertisingLogosComponent,
    SupportCenterButtonComponent,
    AdvertisingTableComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgChartsModule,
    CdkTableModule,
    MatAutocompleteModule,
    NgxDropzoneModule,
    MatTooltipModule,
    DragDropModule,
    MatDatepickerModule,
    PipesModule,
    NotificationsModule,
    MatDividerModule,
    MatListModule,
    TextMaskModule,
    MatInputModule,
    MatSortModule,
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    ImagePlaceholderComponent,
    NotFoundErrorComponent,
    SpinnerComponent,
    TranslatePlaceholdersComponent,
    AutocompleteClientComponent,
    AutocompleteVehicleComponent,
    ClientCardComponent,
    ClientCardSimpleComponent,
    ClientTableCellComponent,
    ColorComponent,
    CreateAdministrationDocumentButtonComponent,
    EntityGroupComponent,
    FieldFormComponent,
    FieldFormInputsComponent,
    FieldTableValueComponent,
    IdentificationComponent,
    ContentShimmerComponent,
    ShimmerComponent,
    TableShimmerComponent,
    PaginatorComponent,
    VehicleComponent,
    VehicleCardComponent,
    VehicleIdentificationTableCellComponent,
    VehicleModelAutocompleteComponent,
    VehicleBrandAutocompleteComponent,
    VehicleTableCellComponent,
    ShareVehicleWidgetComponent,
    FormShimmerComponent,
    FormSpinnerComponent,
    FormTabsComponent,
    TooltipComponent,
    IfAccountFeatureFlagDirective,
    VehicleAutocompleteMarketComponent,
    PrefillTemplateCardComponent,
    PrefillTemplateCellComponent,
    VehicleCardSmallComponent,
    VehicleNameComponent,
    FilesTableComponent,
    FileTableValueComponent,
    NumberingItemComponent,
    AdministrationSidebarComponent,
    FieldErrorsComponent,
    AutocompleteSpaceInvoicesDocumentComponent,
    DocumentStatusComponent,
    IfIntegrationOnDirective,
    BottomDrawerComponent,
    LeadContactUsComponent,
    IfIntegrationOffDirective,
    MotiveServicePromoCardComponent,
    MotiveServiceIssueWarrantyCardComponent,
    NlbLeasePromoCardComponent,
    LeasingStatusComponent,
    AmpStatusComponent,
    LeadContactOtherComponent,
    VehicleStatusComponent,
    OverviewCardWrapperComponent,
    OverviewCardComponent,
    ChartComponent,
    VehicleCreateByVinComponent,
    HelpContactUsComponent,
    AutocompleteUserComponent,
    LeanPayPromoCardComponent,
    TextInputComponent,
    NumberInputComponent,
    DateInputComponent,
    DropdownInputComponent,
    PhoneNumberInputComponent,
    AssetTableCellComponent,
    AutocompleteUserComponent,
    AutocompleteClientBusinessUnitComponent,
    MaskedTextInputComponent,
    NlbLeaseGoUxPromoCardComponent,
    PostInputComponent,
    PartnersPortalGenericPromoCardComponent,
    LeadPartnersPortalComponent,
    PackageCardComponent,
    InvoiceLimitCardComponent,
    RegistrationPageProductPackageFeaturesComponent,
    CancelSubscriptionFeedbackComponent,
    VehicleCardPresentationComponent,
    VerticalShimmerComponent,
    IfPermissionDirective,
    IfAccountCountryDirective,
    AutocompleteAnyDocumentComponent,
    AddikoPromoCardComponent,
    IfStagingEnvironmentDirective,
    EmailInputComponent,
    DropdownSearchInputComponent,
    CheckboxInputComponent,
    VehicleAdvertisingLogosComponent,
    AdvertisingTableComponent,
    SupportCenterButtonComponent
  ],
})
export class PartialsModule {
}
