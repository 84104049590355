<nav class="text-base flex flex-row justify-between border-b border-grayLight bg-white" style="z-index: 51">
  <div class="shrink-0" [style.background]="NavbarColor">
    <a [routerLink]="'/admin/dashboard'" i18n-title title="Domov">
      <!-- Full logo -->
      <div class="hidden md:block py-4 px-4 md:pl-8 md:pr-10 w-sidebar group">
        <svg width="147" height="20" viewBox="0 0 176 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left: 6px;
    margin-top: 2px;">
          <g clip-path="url(#clip0)">
            <path
                    d="M22.1502 6.46466V1.54135H12.3082V6.46466C12.2881 7.12356 12.4004 7.7798 12.6387 8.39445C12.8769 9.0091 13.2362 9.56964 13.6951 10.0428C14.1541 10.516 14.7034 10.8923 15.3104 11.1492C15.9175 11.4061 16.57 11.5385 17.2292 11.5385C17.8884 11.5385 18.5409 11.4061 19.148 11.1492C19.7551 10.8923 20.3044 10.516 20.7633 10.0428C21.2223 9.56964 21.5815 9.0091 21.8198 8.39445C22.058 7.7798 22.1704 7.12356 22.1502 6.46466"
                    fill="white"/>
            <path
                    d="M17.2316 23.6919H22.1503V13.8498H17.2316C16.5727 13.8297 15.9165 13.9421 15.3019 14.1803C14.6872 14.4185 14.1267 14.7778 13.6535 15.2367C13.1803 15.6957 12.804 16.245 12.5471 16.8521C12.2902 17.4591 12.1578 18.1116 12.1578 18.7708C12.1578 19.4301 12.2902 20.0825 12.5471 20.6896C12.804 21.2967 13.1803 21.846 13.6535 22.305C14.1267 22.7639 14.6872 23.1232 15.3019 23.3614C15.9165 23.5996 16.5727 23.712 17.2316 23.6919"
                    fill="white"/>
            <path
                    d="M4.92331 1.54135H0V11.388H4.92331C6.22906 11.388 7.48132 10.8693 8.40462 9.94597C9.32792 9.02267 9.84663 7.77041 9.84663 6.46466C9.84663 5.15892 9.32792 3.90666 8.40462 2.98336C7.48132 2.06006 6.22906 1.54135 4.92331 1.54135"
                    fill="white" class="group-hover:fill-current group-hover:text-green fadeInFadeOut"/>
            <path
                    d="M4.92331 13.8496C6.22906 13.8496 7.48132 14.3683 8.40462 15.2916C9.32792 16.2149 9.84663 17.4672 9.84663 18.773V23.6917H0V18.773C0 17.4672 0.518705 16.2149 1.442 15.2916C2.36531 14.3683 3.61757 13.8496 4.92331 13.8496"
                    fill="white"/>
            <path
                    d="M36.7224 1.51851L27.1472 23.6964H30.6718L33.1288 17.8943H43.7117L46.2331 23.6964H49.8773L40.2423 1.51851H36.7224ZM42.3957 14.8621H34.4724L38.4202 5.68722L42.3957 14.8621Z"
                    fill="white"/>
            <path
                    d="M62.2225 16.4955C62.2225 19.4449 60.6535 21.1335 57.9158 21.1335C55.178 21.1335 53.609 19.4449 53.609 16.4955V7.29302H50.3882V16.3114C50.3882 21.1243 53.2041 24.0001 57.9204 24.0001C62.6366 24.0001 65.4572 21.1243 65.4572 16.3114V7.28842H62.2363L62.2225 16.4955Z"
                    fill="white"/>
            <path
                    d="M73.8359 2.73315H70.615V7.28837H67.4264V10.1825H70.615V17.4065C70.615 19.9371 71.3834 21.8467 72.842 22.9279C73.7783 23.6006 74.9106 23.9451 76.0629 23.908C77.0538 23.896 78.0379 23.7411 78.9846 23.4479L79.3159 23.3467V20.3973L78.7178 20.5767C76.9831 21.1059 75.704 21.0782 74.9172 20.4985C74.2086 19.9694 73.8497 18.9525 73.8497 17.4663V10.1825H79.3159V7.28837H73.8359V2.73315Z"
                    fill="white"/>
            <path
                    d="M89.135 6.98457C88.0129 6.96122 86.8976 7.16557 85.8567 7.58527C84.8157 8.00497 83.8706 8.63131 83.0785 9.42646C82.2863 10.2216 81.6636 11.1691 81.2478 12.2116C80.8321 13.2542 80.632 14.3702 80.6596 15.4922C80.6596 17.7401 81.5525 19.8958 83.142 21.4853C84.7314 23.0748 86.8872 23.9677 89.135 23.9677C91.3828 23.9677 93.5386 23.0748 95.1281 21.4853C96.7175 19.8958 97.6105 17.7401 97.6105 15.4922C97.6381 14.3702 97.438 13.2542 97.0222 12.2116C96.6065 11.1691 95.9837 10.2216 95.1916 9.42646C94.3994 8.63131 93.4543 8.00497 92.4134 7.58527C91.3724 7.16557 90.2572 6.96122 89.135 6.98457V6.98457ZM89.135 21.1333C86.227 21.1333 83.9494 18.6579 83.9494 15.4922C83.9494 12.3266 86.25 9.84653 89.135 9.84653C92.02 9.84653 94.3252 12.3266 94.3252 15.4922C94.3252 18.6579 92.0246 21.1333 89.135 21.1333Z"
                    fill="white"/>
            <path
                    d="M114.778 12.4234C115.573 11.8829 116.223 11.1538 116.669 10.3011C117.114 9.44852 117.342 8.49888 117.331 7.53691C117.331 4.1044 114.617 1.51851 111.009 1.51851H100.334V23.6964H111.635C115.242 23.6964 117.957 21.1197 117.957 17.7148C117.978 16.6231 117.691 15.5476 117.129 14.6116C116.566 13.6757 115.751 12.9173 114.778 12.4234V12.4234ZM110.696 11.0753H103.684V4.56452H110.696C111.127 4.55476 111.554 4.63324 111.953 4.79515C112.352 4.95705 112.714 5.19893 113.015 5.50588C113.317 5.81283 113.553 6.17835 113.708 6.57989C113.863 6.98143 113.934 7.41048 113.917 7.84059C113.932 8.26857 113.859 8.695 113.702 9.09365C113.546 9.49229 113.309 9.8547 113.008 10.1586C112.706 10.4625 112.345 10.7014 111.948 10.8606C111.55 11.0198 111.124 11.096 110.696 11.0845V11.0753ZM103.684 14.1213H111.304C111.734 14.1115 112.162 14.19 112.561 14.3519C112.959 14.5138 113.321 14.7557 113.623 15.0626C113.924 15.3696 114.16 15.7351 114.315 16.1366C114.47 16.5382 114.542 16.9672 114.524 17.3973C114.539 17.8253 114.466 18.2518 114.31 18.6504C114.153 19.049 113.917 19.4115 113.615 19.7153C113.313 20.0192 112.953 20.2581 112.555 20.4173C112.158 20.5766 111.732 20.6528 111.304 20.6412H103.684V14.1213Z"
                    fill="white"/>
            <path
                    d="M123.607 8.89885V7.28842H120.386V23.6964H123.607V14.1857C123.592 13.5945 123.707 13.0073 123.946 12.4662C124.185 11.9252 124.541 11.4438 124.988 11.0568C126.005 10.1964 127.454 9.87431 129.074 10.1366L129.607 10.2286V7.14578L129.23 7.07677C128.215 6.88953 127.17 6.95807 126.188 7.27619C125.206 7.59431 124.319 8.15199 123.607 8.89885V8.89885Z"
                    fill="white"/>
            <path d="M134.935 7.28842H131.705V23.6964H134.935V7.28842Z" fill="white"/>
            <path
                    d="M133.321 1.21475C133.056 1.21168 132.794 1.26143 132.548 1.3611C132.303 1.46078 132.081 1.60836 131.893 1.7952C131.706 1.98204 131.558 2.20438 131.458 2.44919C131.358 2.694 131.307 2.95636 131.31 3.22088C131.31 3.75416 131.522 4.2656 131.899 4.64269C132.276 5.01978 132.787 5.23162 133.321 5.23162C133.854 5.23162 134.365 5.01978 134.742 4.64269C135.119 4.2656 135.331 3.75416 135.331 3.22088C135.334 2.95636 135.283 2.694 135.183 2.44919C135.083 2.20438 134.935 1.98204 134.748 1.7952C134.56 1.60836 134.338 1.46078 134.093 1.3611C133.848 1.26143 133.585 1.21168 133.321 1.21475Z"
                    fill="white"/>
            <path
                    d="M146.135 6.98457C145.013 6.96122 143.898 7.16557 142.857 7.58527C141.816 8.00497 140.871 8.63131 140.079 9.42646C139.286 10.2216 138.664 11.1691 138.248 12.2116C137.832 13.2542 137.632 14.3702 137.66 15.4922C137.632 16.6143 137.832 17.7303 138.248 18.7728C138.664 19.8154 139.286 20.7629 140.079 21.558C140.871 22.3532 141.816 22.9795 142.857 23.3992C143.898 23.8189 145.013 24.0233 146.135 23.9999C149.908 23.9999 152.853 21.9616 154.012 18.5567L154.215 17.9447H150.796L150.672 18.207C150.293 19.0909 149.659 19.8416 148.85 20.363C148.042 20.8843 147.097 21.1526 146.135 21.1333C143.609 21.1333 141.534 19.2929 141.051 16.6517H154.551L154.578 16.2238C154.689 15.0316 154.551 13.8295 154.172 12.6936C153.794 11.5577 153.183 10.5129 152.379 9.62567C151.578 8.77632 150.61 8.10288 149.535 7.64818C148.46 7.19348 147.302 6.96749 146.135 6.98457ZM146.135 9.84653C147.272 9.85823 148.374 10.2447 149.269 10.9459C150.164 11.6472 150.803 12.6242 151.086 13.7254H141.189C141.479 12.6287 142.119 11.6566 143.012 10.9566C143.904 10.2566 145.001 9.86686 146.135 9.84653Z"
                    fill="white"/>
            <path
                    d="M163.21 3.44174C163.831 2.90339 164.945 2.77916 166.716 3.04143L167.245 3.11965V0.216276L166.85 0.15646C164.172 -0.234644 162.248 0.105847 160.969 1.21014C159.69 2.31444 159.156 3.99388 159.156 6.50155V7.28836H155.664V10.1825H159.156V23.6963H162.377V10.1825H167.236V7.28836H162.386V6.44174C162.386 4.85891 162.64 3.93407 163.21 3.44174Z"
                    fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="175.123" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>

      <!-- Just emblem -->
      <div class="block md:hidden py-4 px-4 md:pl-8 md:pr-10">
        <svg class="block md:hidden" width="23" height="23" viewBox="0 0 152 152" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
                  d="M34.2799 84.72C52.8399 84.72 67.8799 99.76 67.8799 118.32V151.92H34.2799H0.679932V118.32C0.679932 99.76 15.7199 84.72 34.2799 84.72ZM34.2799 0.709961H0.679932V34.3101V67.91H34.2799C52.8399 67.91 67.8799 52.8701 67.8799 34.3101C67.8799 15.7501 52.8399 0.709961 34.2799 0.709961ZM118.29 151.92H151.89V118.32V84.72H118.29C99.7299 84.72 84.6899 99.76 84.6899 118.32C84.6899 136.88 99.7299 151.92 118.29 151.92ZM151.89 34.3101V0.709961H118.29H84.6899V34.3101C84.6899 52.8701 99.7299 67.91 118.29 67.91C136.85 67.92 151.89 52.8701 151.89 34.3101Z"
                  fill="white"></path>
        </svg>
      </div>
    </a>
  </div>


  <ng-container *ngIf="isLoggedIn()">

    <div class="inline-block items-center" style="margin: auto 0 auto 7px">
      <app-global-search-bar *appIfPermission="[PermissionEnum.GLOBAL_SEARCH_VIEW]"></app-global-search-bar>
    </div>
    <div class="relative ml-auto flex flex-row items-center">

      <ng-container *ngIf="(accountConfig$ | async) as accountConfig">
        <ng-container
                *ngIf="accountConfig.activeAccountProductPackage?.isTrial || accountConfig.activeAccountProductPackage?.to">

              <span class="hidden md:flex">
                <!--      <ng-container *ngTemplateOutlet="administrationLinks"></ng-container>-->

                <!--                <div class="relative my-auto" *ngIf="accountConfig.daysTillEndOfSubscription > 0; else expired">-->
                <div class="relative my-auto" *ngIf="accountConfig.daysTillEndOfSubscription >= 0">
                  <a [routerLink]="['/admin/settings/packages-page']">

                  <div
                          class="border-2 w-full p-2 flex justify-center items-center rounded"
                          [ngClass]="{'border-primary text-primary': accountConfig.daysTillEndOfSubscription > 10, 'border-yellow text-yellow': accountConfig.daysTillEndOfSubscription <= 10 && accountConfig.daysTillEndOfSubscription > 5, 'border-orange text-orange': accountConfig.daysTillEndOfSubscription <= 5}"
                  >
                    <span *ngIf="accountConfig.activeAccountProductPackage.isTrial" i18n>Testno obdobje traja še:</span>
                    <span *ngIf="!accountConfig.activeAccountProductPackage.isTrial" i18n>Naročnina traja še:</span>
                    <span class="font-bold"> &nbsp;

                    <ng-container [ngSwitch]="accountConfig.daysTillEndOfSubscription">
                      <span *ngSwitchCase="1" i18n>{{ accountConfig.daysTillEndOfSubscription }} dan</span>
                      <span *ngSwitchCase="2" i18n>{{ accountConfig.daysTillEndOfSubscription }} dneva</span>
                      <span *ngSwitchDefault i18n>{{ accountConfig.daysTillEndOfSubscription }} dni</span>
                    </ng-container>
                  </span>
                  </div>
                  </a>
                </div>
                <ng-template #expired>
                  <a [routerLink]="['/admin/settings/packages-page']">
                  <div class="relative my-auto">
                    <div
                            class="border border-2 w-full p-2 flex justify-center items-center rounded border-red text-red"
                    >
                      <span i18n>Naročnina je potekla !</span>
                    </div>
                  </div>
                  </a>
                </ng-template>
              </span>
        </ng-container>
      </ng-container>

      <div class="hidden md:inline relative my-auto" *ngIf="businessUnits?.length > 0">
        <div class="dropdown business-units-dropdown">
          <select [formControl]="businessUnitsControl">
            <option [ngValue]="ALL_BUSINESS_UNITS" i18n selected>-- VSE ENOTE --</option>
            <option *ngFor="let businessUnit of businessUnits;" [ngValue]="businessUnit.uuid">
              {{ businessUnit.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="hidden md:inline relative my-auto" *ngIf="account">
        <div class="ml-2 border-transparent px-4 py-2 border-2 rounded bg-greenWashed">
          <div class="text-xs text-greenDarkOlive font-semibold">
            {{ account.name }}
          </div>
        </div>
      </div>

      <div class="hidden md:inline pl-6 py-3 hover:text-greenHover">
        <span class="fa-stack cursor-pointer" [attr.data-count]="unreadNotifications"
              *ngIf="unreadNotifications > 0; else bell"
              (click)="toggleNotificationDropdown();">
          <i class="relative fas fa-bell inline text-grayDark fa-stack-1x fa-inverse hover:text-inherit">
          </i>
        </span>
        <ng-template #bell>
          <span class="fa-stack cursor-pointer"
                (click)="toggleNotificationDropdown();">
            <i class="relative fas fa-bell inline text-grayDark fa-stack-1x fa-inverse hover:text-inherit"></i>
          </span>
        </ng-template>
      </div>

      <div class="absolute" style="right: 50px; top: 50px;" *ngIf="isNotificationDropdownOpened">
        <app-notification-dropdown [notifications]="notifications" [totalNotifications]="totalNotifications"
                                   (closeDropdown)="toggleNotificationDropdown()"
                                   (readAllNotification)="clearUnreadNotifications()">
        </app-notification-dropdown>
      </div>

      <div class="hidden md:inline relative my-auto text-grayDark hover:text-greenHover">

        <button class="users px-6 shrink-0 flex flex-row items-center pl-3 py-4" type="button"
                data-test="layout-navbar-user-menu-button"
                i18n-title
                title="Uporabnik"
                (click)="toggleUserMenu()">
          <i class="fas fa-user-circle  mr-3 text-lg text-inherit"></i>

          <ng-container *ngIf="user">
            <span class="mr-3 font-bold text-xs hidden md:inline text-inherit">{{ getUser(user) }} </span>
          </ng-container>

          <i class="fas fa-chevron-down text-sm text-inherit"></i>
        </button>

        <ng-container *ngIf="isUserMenuOpened">
          <div
                  class="bg-white rounded-bl border-grayLight border rounded-br p-3 right-0 w-56 absolute flex flex-col"
                  style="margin-top:2px; z-index: 500;">
            <ng-container *ngTemplateOutlet="userMenu"></ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <button class="btn text-grayDark md:hidden btn-grayDark-link text-lg shadow-none"
            (click)="toggleHamburgerMenu()">
      <i class="fas fa-bars"></i>
    </button>
  </ng-container>
</nav>

<ng-container *ngIf="isHamburgerOpened">
  <div class="cdk-overlay-container cdk-overlay-dark-backdrop">
    <div></div>
  </div>

  <div class="fixed bottom-0 left-0 right-0 bg-white z-50 hamburger" style="z-index: 1001">
    <div class="relative">
      <div class="flex flex-row justify-between items-center">
        <p class="text-base text-grayDark font-bold ml-5 mt-3" i18n="Navbar user menu hamburger">Meni</p>
        <div class="text-right mb-1 mt-3 pr-2">
          <button (click)="toggleHamburgerMenu()" class="btn border-transparent shadow-none"><i
                  class="fas fa-times text-lg text-gray"></i></button>
        </div>
      </div>
      <hr class="mt-2 mb-0">

      <div class="card-content pt-4 overflow-y-auto pb-10" style="max-height: 70vh">
        <div class="relative my-auto" *ngIf="businessUnits?.length > 0">
          <div class="dropdown business-units-dropdown">
            <select [formControl]="businessUnitsControl">
              <option [ngValue]="ALL_BUSINESS_UNITS" i18n selected>-- VSE ENOTE --</option>
              <option *ngFor="let businessUnit of businessUnits;" [ngValue]="businessUnit.uuid">
                {{ businessUnit.name }}
              </option>
            </select>
          </div>
        </div>
        <hr class="mt-2">

        <app-sidebar [isMobile]="true">
          <div>
            <ng-container *ngTemplateOutlet="administrationLinks"></ng-container>
          </div>
        </app-sidebar>

        <hr class="mt-2">

        <div class="flex flex-col font-semibold" *ngIf="user">
          <ng-container *ngTemplateOutlet="userMenu"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #userMenu>

  <a [routerLink]="['/admin/settings/public-account']" *ngIf="user?.isAdmin" class="text-grayDark p-3"><i
          class="fas fa-user-cog mr-2"></i><span i18n="Navbar user menu">Nastavitve</span></a>
  <a [routerLink]="['/admin/settings/profile']" *ngIf="!user?.isAdmin" class="text-grayDark p-3"><i
          class="fas fa-user-cog mr-2"></i><span i18n="Navbar user menu">Nastavitve</span></a>
  <a [routerLink]="['/logout']" class="text-red p-3"><i class="fas fa-sign-out-alt mr-2"></i><span
          i18n="Navbar user menu">Odjava</span></a>

  <div class="md:hidden px-2 py-2">
    <app-support-center-button ></app-support-center-button>
  </div>

  <ng-container *ngIf="user?.isConfigurationAdmin">
    <hr class="mt-2 mb-0">
    <a [routerLink]="['/admin/settings/super-admin/accounts', account.accountUuid]" class="text-red p-3"
       data-test="navbar-usermenu-accountconfig"
    >
      <i class="fas fa-screwdriver mr-2"></i><span i18n="Navbar user menu">Account Config</span>
    </a>
    <a [routerLink]="['/admin/settings/super-admin/trading-hub', account.accountUuid]" class="text-red p-3"
       data-test="navbar-usermenu-tradinghubconfig"
    >
      <i class="fas fa-screwdriver mr-2"></i><span i18n="Navbar user menu">Trading hub Config</span>
    </a>
    <a [routerLink]="['/admin/settings/super-admin/accounts']" class="text-red p-3"
       data-test="navbar-usermenu-superaccounts"
    >
      <i class="fas fa-users mr-2"></i><span i18n="Navbar user menu">Super Accounts</span>
    </a>
  </ng-container>
</ng-template>

<ng-template #administrationLinks>
  <!--  <a *ngIf="hasAdministration && !isInAdministration && !hasAdministrationV2" title="Administracija"-->
  <!--     i18n-title="Navbar link"-->
  <!--     [routerLink]="getAdministrationUrl()"-->
  <!--     class="py-4 text-administration hover:no-underline font-bold text-sm hover:no-underline no-underline">-->
  <!--    <span class="text-lg opacity-75 w-12"><i class="fas fa-file-alt mr-2"></i></span>-->
  <!--    <span i18n="Navbar link">Administracija</span>-->
  <!--  </a>-->

  <!--  <a *ngIf="hasAdministration && isInAdministration && !hasAdministrationV2" title="Nazaj" i18n-title="Navbar link"-->
  <!--     [routerLink]="['/admin']" class="py-4 px-6 text-primary hover:no-underline no-underline font-bold text-sm">-->
  <!--    <span class="text-lg opacity-75 w-12"><i class="fas fa-arrow-left mr-2"></i></span>-->
  <!--    <span i18n="Navbar link">Nazaj v AutoBrief</span>-->
  <!--  </a>-->
</ng-template>
